import React, { Component } from 'react';
import PropTypes from 'prop-types';

class team extends Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {

    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {

    }

    shouldComponentUpdate(nextProps, nextState) {

    }

    componentWillUpdate(nextProps, nextState) {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {

    }

    render() {
        return (
            <div>
                <section class="page-section bg-light" id="team">
                    <div class="container">
                        <div class="text-center">
                            <h2 class="section-heading text-uppercase">Our Organization</h2>
                            {/* <h3 class="section-subheading text-muted">Lorem ipsum dolor sit amet consectetur.</h3> */}
                        </div>
                        <div class="row">                            
                            <div class="col-lg-12">
                                <div class="team-member">
                                    <img class="mx-auto rounded-circle" src="assets/img/team/2.jpg" alt="..." />
                                    <h4>Nguyen Manh Tien</h4>
                                    <p class="text-muted">CEO</p>
                                    <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-twitter"></i></a>
                                    <a class="btn btn-dark btn-social mx-2" href="https://www.facebook.com/nguyen.manhtien.5"><i class="fab fa-facebook-f"></i></a>
                                    <a class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/nguyen-tien-768742226/"><i class="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                        <div class="col-lg-12">
                                <div class="team-member">
                                    <img class="mx-auto rounded-circle" src="assets/img/team/1.jpg" alt="..." />
                                    <h4>Dieter Michael</h4>
                                    <p class="text-muted">Consultant</p>
                                    <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-twitter"></i></a>
                                    <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-facebook-f"></i></a>
                                    <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3">
                                <div class="team-member">
                                    <img class="mx-auto rounded-circle" src="assets/img/team/Duong.jpg" alt="..." />
                                    <h4>Nguyen Van Duong</h4>
                                    <p class="text-muted">Project Director</p>
                                    <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-twitter"></i></a>
                                    <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-facebook-f"></i></a>
                                    <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="team-member">
                                    <img class="mx-auto rounded-circle" src="assets/img/team/Xuane.jpg" alt="..." />
                                    <h4>Le Truong Xuan</h4>
                                    <p class="text-muted">Project Manager</p>
                                    <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-twitter"></i></a>
                                    <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-facebook-f"></i></a>
                                    <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="team-member">
                                    <img class="mx-auto rounded-circle" src="assets/img/team/Xuan.jpg" alt="..." />
                                    <h4>Le Anh Xuan</h4>
                                    <p class="text-muted">Project Engineering Manager</p>
                                    <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-twitter"></i></a>
                                    <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-facebook-f"></i></a>
                                    <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="team-member">
                                    <img class="mx-auto rounded-circle" src="assets/img/team/Tuyen.png" alt="..." />
                                    <h4>Pham Huu Tuyen</h4>
                                    <p class="text-muted">Project Engineering Manager</p>
                                    <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-twitter"></i></a>
                                    <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-facebook-f"></i></a>
                                    <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            {/* <div class="col-lg-8 mx-auto text-center"><p class="large text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut eaque, laboriosam veritatis, quos non quis ad perspiciatis, totam corporis ea, alias ut unde.</p></div> */}
                        </div>
                    </div>
                </section>        
            </div>
        );
    }
}

team.propTypes = {

};

export default team;