import React, { Component } from 'react';
import PropTypes from 'prop-types';
import emailjs from 'emailjs-com'
import{ init } from 'emailjs-com';


class contact extends Component {
    constructor(props) {
        super(props);
        this.state={
            name:"",
            email:"",
            phone:"",
            message:""
        };
        init("user_ZQVdipysCeWGw1LYx7U1w");
    }

    componentWillMount() {

    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {

    }

    shouldComponentUpdate(nextProps, nextState) {

    }

    componentWillUpdate(nextProps, nextState) {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {

    }
    onChange=(e)=>{
        var target = e.target;
        var name = target.name;
        var value = target.value;
        this.setState({
          [name]: value
        });    
      }

    onSubmit=(e)=>{
        e.preventDefault();       
        // Email.send({
        //     Host : "smtp.yourisp.com",
        //     Username : "username",
        //     Password : "password",
        //     To : 'them@website.com',
        //     From : "you@isp.com",
        //     Subject : "This is the subject",
        //     Body : "And this is the body"
        // }).then(
        //   message => alert(message)
        // ); 
        // emailjs.sendForm("service_pxjl0cu","template_v3yp9j4",e.target,"user_ZQVdipysCeWGw1LYx7U1w");
        
        emailjs.send("service_pxjl0cu","template_v3yp9j4",{
            name: this.state.name,
            message: this.state.message,
            phone: this.state.phone,
            email: this.state.email,
            });
    }
    render() {
        return (
            <div>
                <section className="page-section" id="contact">
                    <div className="container">
                        <div className="text-center">
                            <h2 className="section-heading text-uppercase">Contact Us</h2>
                            {/* <h3 className="section-subheading text-muted">Lorem ipsum dolor sit amet consectetur.</h3> */}
                        </div>
                        {/* <!-- * * * * * * * * * * * * * * *-->
                        <!-- * * SB Forms Contact Form * *-->
                        <!-- * * * * * * * * * * * * * * *-->
                        <!-- This form is pre-integrated with SB Forms.-->
                        <!-- To make this form functional, sign up at-->
                        <!-- https://startbootstrap.com/solution/contact-forms-->
                        <!-- to get an API token!--> */}
                        <form id="contactForm" data-sb-form-api-token="API_TOKEN" >
                        {/* <form id="contactForm" onSubmit={this.onSubmit}> */}
                            <div className="row align-items-stretch mb-5">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        {/* <!-- Name input--> */}
                                        <input className="form-control" id="name" type="text" name = "name" placeholder="Your Name *" data-sb-validations="required" onChange={this.onChange}/>
                                        <div className="invalid-feedback" data-sb-feedback="name:required">A name is required.</div>
                                    </div>
                                    <div className="form-group">
                                        {/* <!-- Email address input--> */}
                                        <input className="form-control" id="email" type="email" name = "email" placeholder="Your Email *" data-sb-validations="required,email" onChange={this.onChange}/>
                                        <div className="invalid-feedback" data-sb-feedback="email:required">An email is required.</div>
                                        <div className="invalid-feedback" data-sb-feedback="email:email">Email is not valid.</div>
                                    </div>
                                    <div className="form-group mb-md-0">
                                        {/* <!-- Phone number input--> */}
                                        <input className="form-control" id="phone" type="tel" name = "phone" placeholder="Your Phone *" data-sb-validations="required" onChange={this.onChange}/>
                                        <div className="invalid-feedback" data-sb-feedback="phone:required">A phone number is required.</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group form-group-textarea mb-md-0">
                                        {/* <!-- Message input--> */}
                                        <textarea className="form-control" id="message" name="message" placeholder="Your Message *" data-sb-validations="required" onChange={this.onChange}>                                            
                                        </textarea>
                                        <div className="invalid-feedback" data-sb-feedback="message:required">A message is required.</div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Submit success message-->
                            <!---->
                            <!-- This is what your users will see when the form-->
                            <!-- has successfully submitted--> */}
                            <div className="d-none" id="submitSuccessMessage">
                                <div className="text-center text-white mb-3">
                                    <div className="fw-bolder">Form submission successful!</div>
                                    {/* To activate this form, sign up at
                                    <br />
                                    <a href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a> */}
                                </div>
                            </div>
                            {/* <!-- Submit error message-->
                            <!---->
                            <!-- This is what your users will see when there is-->
                            <!-- an error submitting the form--> */}
                            <div className="d-none" id="submitErrorMessage"><div className="text-center text-danger mb-3">Error sending message!</div></div>
                            {/* <!-- Submit Button--> */}
                            <div className="text-center"><button className="btn btn-primary btn-xl text-uppercase" id="submitButton" type="submit">Send Message</button></div>
                        </form>

                        
                    </div>
                </section>
       
            </div>
        );
    }
}

contact.propTypes = {

};

export default contact;