import React, { Component } from 'react';
import PropTypes from 'prop-types';

class about extends Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {

    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {

    }

    shouldComponentUpdate(nextProps, nextState) {

    }

    componentWillUpdate(nextProps, nextState) {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {

    }

    render() {
        return (
            <div>
                <section className="page-section" id="about">
                    <div className="container">
                        <div className="text-center">
                            <h2 className="section-heading text-uppercase">Experiences</h2>
                            <h3 className="section-subheading text-muted">We recognise that achieving a sustainable future is one of the biggest challenges facing our generation.
                                It underpins all that we do. It is what unites us and what makes us unique. 
                                We are led by our purpose to unlock solutions to the world’s most critical challenges, in energy and the built environment.
                            </h3>                            
                        </div>
                        <ul className="timeline">
                            <li>
                                <div className="timeline-image">                                
                                    {/* <img className="rounded-circle img-fluid" src="#" alt="..." /> */}
                                    <h4 className = "timeline-heading"><br/>    2010-2011</h4>
                                </div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4>CPC TALIN ALKYLATION PLANT</h4>
                                        <h4 className="subheading">Kaohsiung, Taiwan</h4>
                                    </div>
                                    <div className="timeline-body">
                                        {/* <p className="text-muted">Describe about this project</p> */}
                                    </div>                                    
                                </div>  
                                {/* <div className="timeline-panel">
                                    <img className="img-fluid" src="assets/img/portfolio/1.jpg" alt="..." />
                                </div>                               */}
                            </li>               
                            <li className="timeline-inverted">
                                {/* <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/2.jpg" alt="..." /></div> */}
                                <div className="timeline-image">                                                                    
                                    <h4 className = "timeline-heading"><br/>2011-2012</h4>
                                </div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4>CCP-PHENOL PHASE II</h4>
                                        <h4 className="subheading">ChangSu, China</h4>
                                    </div>
                                    <div className="timeline-body">
                                        {/* <p className="text-muted">Describe about this project</p> */}
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="timeline-image">                                                                    
                                    <h4 className = "timeline-heading"><br/>2012-2013</h4>
                                </div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4>MESSER PHASE II</h4>
                                        <h4 className="subheading">KinhMon, HaiDuong, Vietnam</h4>
                                    </div>
                                    <div className="timeline-body"><p className="text-muted">ASU 8500NM3/H & 180TPD Liquefier Plant</p></div>
                                </div>
                            </li>
                            <li className="timeline-inverted">
                                <div className="timeline-image">                                                                    
                                    <h4 className = "timeline-heading"><br/>2013-2014</h4>
                                </div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4>LAFFAN Refinery phase II</h4>
                                        <h4 className="subheading">Ras Laffan Industrial City, Qatar</h4>
                                    </div>
                                    <div className="timeline-body">
                                        {/* <p className="text-muted">Describe about this project</p> */}
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="timeline-image">                                                                    
                                    <h4 className = "timeline-heading"><br/>2014-2015</h4>
                                </div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4>URENCO-Incineration Plant</h4>
                                        <h4 className="subheading">Hanoi, Vietnam</h4>
                                    </div>
                                    <div className="timeline-body">
                                        {/* <p className="text-muted">Describe about this project</p> */}
                                    </div>
                                </div>
                            </li>
                            <li className="timeline-inverted">
                                <div className="timeline-image">                                                                    
                                    <h4 className = "timeline-heading"><br/>2015</h4>
                                </div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4>Saudi Methacrylate Company(SAMAC)</h4>
                                        <h4 className="subheading">Jubail Industrial City, Kingdom of Saudi Arabia </h4>
                                    </div>
                                    <div className="timeline-body">
                                        <p className="text-muted">MMA-PMMA(Monomer and Poly Methyl Methacrylate) Plant</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="timeline-image">                                                                    
                                    <h4 className = "timeline-heading"><br/>2015-2016</h4>
                                </div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4>Petronas Refinery And Petrochemical Integrated Development Complex</h4>
                                        <h4 className="subheading">Pengerang, Johor, Malaysia </h4>
                                    </div>
                                    <div className="timeline-body">
                                        <p className="text-muted">Package No.1 EPCC of RFCC, LTU, PRU Unit</p>
                                    </div>
                                </div>
                            </li>
                            <li className="timeline-inverted">
                                <div className="timeline-image">                                                                    
                                    <h4 className = "timeline-heading"><br/>2016</h4>
                                </div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4>Liwa Plastics Project</h4>
                                        <h4 className="subheading">Suhar Industrial Port Area, Oman </h4>
                                    </div>
                                    <div className="timeline-body">
                                        {/* <p className="text-muted">Describe about this project</p> */}
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="timeline-image">                                                                    
                                    <h4 className = "timeline-heading"><br/>2016-2017</h4>
                                </div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4>CEPSA Alkylation Unit Revamp Project</h4>
                                        <h4 className="subheading">Refinería Gibraltar San Roque, Spain</h4>
                                    </div>
                                    <div className="timeline-body">
                                        <p className="text-muted">Workshare with Wood Iberia, Spain</p>
                                    </div>
                                </div>
                            </li>
                            <li className="timeline-inverted">
                                <div className="timeline-image">                                                                    
                                    <h4 className = "timeline-heading"><br/>2017-2018</h4>
                                </div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4>PROGRAMMA VDU PROYECTOS 2015-2017 (P1+P3), BP Oil España S.A.U</h4>
                                        <h4 className="subheading">BP Oil Refinería – Castellón, Spain</h4>
                                    </div>
                                    <div className="timeline-body">
                                        <p className="text-muted">Workshare with Wood Iberia, Spain</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="timeline-image">                                                                    
                                    <h4 className = "timeline-heading"><br/>2018-2020</h4>
                                </div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4>MdA Phase 1 Expansion Project</h4>
                                        <h4 className="subheading">Minera de Antiplano, Argentina </h4>
                                    </div>
                                    <div className="timeline-body">
                                        {/* <p className="text-muted">Describe about this project</p> */}
                                    </div>
                                </div>
                            </li>
                            <li className="timeline-inverted">
                                <div className="timeline-image">                                                                    
                                    <h4 className = "timeline-heading"><br/>2020-2021</h4>
                                </div>
                                <div className="timeline-panel">
                                    <div className="timeline-heading">
                                        <h4>SYMPHONY PROJECT, ARKEMA</h4>
                                        <h4 className="subheading">Banyan Avenue, Singapore</h4>
                                    </div>
                                    <div className="timeline-body">
                                        {/* <p className="text-muted">Describe about this project</p> */}
                                    </div>
                                </div>
                            </li>
                            <li className="timeline-inverted">
                                <div className="timeline-image">
                                    <h4>
                                        Be Part
                                        <br />
                                        Of Our
                                        <br />
                                        Story!
                                    </h4>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>        
            </div>
        );
    }
}

about.propTypes = {

};

export default about;