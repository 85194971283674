import React, { Component } from 'react';
import PropTypes from 'prop-types';

class services extends Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {

    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {

    }

    shouldComponentUpdate(nextProps, nextState) {

    }

    componentWillUpdate(nextProps, nextState) {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {

    }

    render() {
        return (
            <div>
                <section className="page-section" id="services">
                    <div className="container">
                        <div className="text-center">
                            <h2 className="section-heading text-uppercase">Services</h2>
                            <h3 className="section-subheading text-muted">Our business across three primary business units of:</h3>
                        </div>
                        <div className="row text-center">
                            <div className="col-md-4">
                                <span className="fa-stack fa-4x">
                                    <i className="fas fa-circle fa-stack-2x text-primary"></i>
                                    <i className="fas fa-comments fa-stack-1x fa-inverse"></i>
                                </span>
                                <h4 className="my-3">Consulting</h4>
                                <p className="text-muted">Our Consulting business unit is a global, 
                                    end-to-end, multi-sector specialist consultancy, trusted by clients to provide the innovative solutions 
                                    and delivery excellence needed to solve today’s most complex challenges and maximise value across the asset life cycle. 
                                    As the spearhead for the future and its role in a changing world, we are leading strategic growth in 
                                    energy transition and sustainable infrastructure development across a variety of end markets including transportation, 
                                    government, hydrocarbons, industrial, water and power.
                                </p>
                            </div>
                            <div className="col-md-4">
                                <span className="fa-stack fa-4x">
                                    <i className="fas fa-circle fa-stack-2x text-primary"></i>
                                    <i className="fas fa-laptop fa-stack-1x fa-inverse"></i>
                                </span>
                                <h4 className="my-3">Projects</h4>
                                <p className="text-muted">Our Projects business unit specialises in delivering predictable and consistent results in high complexity projects 
                                    that include new technology, challenging construction logistics and, or sheer scale. Just like the nature and expansiveness of our sectors, 
                                    so is our Projects business, in its solutions, abilities and global track record. Across any major capital project lifecycle, we provide a 
                                    full suite of solutions from programme and project management, to engineering and design, procurement, construction and project delivery.
                                </p>
                            </div>
                            <div className="col-md-4">
                                <span className="fa-stack fa-4x">
                                    <i className="fas fa-circle fa-stack-2x text-primary"></i>
                                    <i className="fas fa-cogs fa-stack-1x fa-inverse"></i>
                                </span>
                                <h4 className="my-3">Operations</h4>
                                <p className="text-muted">Our Operations business delivers integrated, agile and intelligent solutions to manage our clients’ assets.</p>
                                <p className="text-muted">We have strong team delivers solutions to optimise our world’s critical assets. From maintenance, modifications, 
                                    brownfield engineering, asset operations and management to completing the lifecycle with decommissioning.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

services.propTypes = {

};

export default services;