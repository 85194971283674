import React, { Component } from 'react';
import PropTypes from 'prop-types';

class navigation extends Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {

    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {

    }

    shouldComponentUpdate(nextProps, nextState) {

    }

    componentWillUpdate(nextProps, nextState) {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {

    }

    render() {
        return (
            <div>                
                <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
                    <div className="container">
                        <a className="navbar-brand" href="#page-top"><img src="assets/img/logo.png" alt="..." /></a>                        
                        {/* <img class="mx-auto rounded-circle w-r5" src="assets/img/logo.png" alt="..." />
                        <h2 className="nav-link">BIG STAR ENGINEERING</h2> */}
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                            Menu
                            <i className="fas fa-bars ms-1"></i>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarResponsive">
                            <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
                                <li className="nav-item"><a className="nav-link" href="#services">Services</a></li>
                                <li className="nav-item"><a className="nav-link" href="#portfolio">Portfolio</a></li>
                                <li className="nav-item"><a className="nav-link" href="#about">Experiences</a></li>
                                <li className="nav-item"><a className="nav-link" href="#team">Organization</a></li>
                                <li className="nav-item"><a className="nav-link" href="#contact">Contact</a></li>
                            </ul>
                        </div>
                    </div>
                </nav>
                
                <header className="masthead">
                    <div className="container">
                        {/* <div className="masthead-subheading">Welcome To Our Project team!</div>
                        <div className="masthead-heading text-uppercase">It's Nice To Meet You</div> */}
                        {/* <a className="btn btn-primary btn-xl text-uppercase" href="#services">Tell Me More</a> */}
                    </div>
                </header>
            </div>
        );
    }
}

navigation.propTypes = {

};

export default navigation;