import React, { Component } from 'react';
import PropTypes from 'prop-types';

class clients extends Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {

    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {

    }

    shouldComponentUpdate(nextProps, nextState) {

    }

    componentWillUpdate(nextProps, nextState) {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {

    }

    render() {
        return (
            <div>
                <div className="py-5">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-3 col-sm-6 my-3">
                                <a href="#!"><img className="img-fluid img-brand d-block mx-auto" src="assets/img/logos/CPC.jfif" alt="..." /></a>
                            </div>
                            <div className="col-md-3 col-sm-6 my-3">
                                <a href="#!"><img className="img-fluid img-brand d-block mx-auto" src="assets/img/logos/Messer.png" alt="..." /></a>
                            </div>
                            <div className="col-md-3 col-sm-6 my-3">
                                <a href="#!"><img className="img-fluid img-brand d-block mx-auto" src="assets/img/logos/laffan.png" alt="..." /></a>
                            </div>
                            <div className="col-md-3 col-sm-6 my-3">
                                <a href="#!"><img className="img-fluid img-brand d-block mx-auto" src="assets/img/logos/Urenco.png" alt="..." /></a>
                            </div>
                            <div className="col-md-3 col-sm-6 my-3">
                                <a href="#!"><img className="img-fluid img-brand d-block mx-auto" src="assets/img/logos/samac.png" alt="..." /></a>
                            </div>
                            <div className="col-md-3 col-sm-6 my-3">
                                <a href="#!"><img className="img-fluid img-brand d-block mx-auto" src="assets/img/logos/petronas.jpg" alt="..." /></a>
                            </div>
                            <div className="col-md-3 col-sm-6 my-3">
                                <a href="#!"><img className="img-fluid img-brand d-block mx-auto" src="assets/img/logos/Liwa.jfif" alt="..." /></a>
                            </div>
                            <div className="col-md-3 col-sm-6 my-3">
                                <a href="#!"><img className="img-fluid img-brand d-block mx-auto" src="assets/img/logos/Cepsa.png" alt="..." /></a>
                            </div>
                            <div className="col-md-3 col-sm-6 my-3">
                                <a href="#!"><img className="img-fluid img-brand d-block mx-auto" src="assets/img/logos/BP.png" alt="..." /></a>
                            </div>
                            <div className="col-md-3 col-sm-6 my-3">
                                <a href="#!"><img className="img-fluid img-brand d-block mx-auto" src="assets/img/logos/arkema.png" alt="..." /></a>
                            </div>
                        </div>
                    </div>
                </div>        
            </div>
        );
    }
}

clients.propTypes = {

};

export default clients;