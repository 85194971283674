import './App.css';
import About from './pages/about'
import Navigation from './components/navigation'
import Services from './components/services'
import Portfolio from './components/portfolio'
import Team from './components/team'
import Clients from './components/clients'
import Contact from './components/contact'

function App() {
  return (
    <div className="App">         
      <Navigation/>
      <Services />
      {/* <Portfolio /> */}
      <About/>
      <Team/>  
      <Clients />  
      {/* <Contact />        */}      
    </div>
  );
}

export default App;
